.button {
  border-radius: 10px;
  border: none;
  width: 160px;
  height: 40px;

  &__primary_a {
    background-color: #41FFB2;
    color: #000000;
  }

  &__primary_b {
    background-color: #000000;
    color: #41FFB2;
    border: solid 2px #41FFB2;
  }

  &__primary_c {
    background-color: transparent;
    color: #000000;
    border: solid 2px #41FFB2;
  }

  &__secondary_a {
    background-color: #C0A3F4;
    color: #000000;
  }

  &__secondary_b {
    background-color: #000000;
    color: #C0A3F4;
    border: solid 2px #C0A3F4;
  }

  &__secondary_c {
    background-color: transparent;
    color: #000000;
    border: solid 2px #C0A3F4;
  }

  &__tertiary_a {
    background-color: #000000;
  }

  &__tertiary_b {
    background-color: transparent;
    color: #000000;
    border: solid 2px #000000;
  }

  &__tertiary_c {
    background-color: transparent;
    color: #ffffff;
    border: solid 2px #ffffff;
  }
}
