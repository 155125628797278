.spacer {
  &__1 {
    height: 20px;
  }

  &__2 {
    height: 40px;
  }

  &__3 {
    height: 60px;
  }
}
