/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 4, 2021 */



@font-face {
    font-family: 'euclid_circular_abold';
    src: url('euclidcirculara-bold-webfont.woff2') format('woff2'),
         url('euclidcirculara-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_abold_italic';
    src: url('euclidcirculara-bolditalic-webfont.woff2') format('woff2'),
         url('euclidcirculara-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_aitalic';
    src: url('euclidcirculara-italic-webfont.woff2') format('woff2'),
         url('euclidcirculara-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_alight';
    src: url('euclidcirculara-light-webfont.woff2') format('woff2'),
         url('euclidcirculara-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_alight_italic';
    src: url('euclidcirculara-lightitalic-webfont.woff2') format('woff2'),
         url('euclidcirculara-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_amedium';
    src: url('euclidcirculara-medium-webfont.woff2') format('woff2'),
         url('euclidcirculara-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_aMdIt';
    src: url('euclidcirculara-mediumitalic-webfont.woff2') format('woff2'),
         url('euclidcirculara-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_aregular';
    src: url('euclidcirculara-regular-webfont.woff2') format('woff2'),
         url('euclidcirculara-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_asemibold';
    src: url('euclidcirculara-semibold-webfont.woff2') format('woff2'),
         url('euclidcirculara-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'euclid_circular_aSBdIt';
    src: url('euclidcirculara-semibolditalic-webfont.woff2') format('woff2'),
         url('euclidcirculara-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}