@import "../../fonts/euclid_circular_a/stylesheet.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~admin-lte/plugins/fontawesome-free/css/all.css";
@import "~font-awesome";
@import "~admin-lte/plugins/select2/css/select2.css";
@import "~slick-carousel/slick/slick-theme";
@import "~slick-carousel/slick/slick";
@import "./colors_pallete";
@import "./buttons";
@import "./spacers";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');


* {
  color: white;
  box-sizing: border-box;
  font-family: "euclid_circular_aregular", 'Open Sans', Helvetica, Arial, "Lucida Grande", sans-serif;
}

#CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}

/* Cambio de diseño de alertas */
.alert {
  background-color: #c0a3f4 !important;
  font-size: 16px;
  color: white;
  z-index: 1;
}

/* Cambio de diseño scrollbar para desktop */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3e3e3e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Cambio de diseño scrollbar para desktop */


.form-control, .form-control:focus, .form-control:focus-visible, .form-control:focus-within, input {
  border-radius: 10px !important;
  background-color: black !important;
  border: solid #c2a5f6 2px !important;
  /*border: solid #33205b 1px;*/
  color: white !important;
}

/* Cambio del color del fondo y del color del texto al usar autocompletado */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: white !important;
  background-color: black !important;
  transition: background-color 5000s ease-in-out 0s;
}

.form-control::placeholder {
  color: white !important;
}

input, textarea {
  background-color: black !important;
  border-radius: 10px !important;
  border: solid #c2a5f6 2px !important;
  /*border: solid #33205b 1px;*/
  color: white !important;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari/Edge */
  color: white !important;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: white !important;
}

input:-ms-input-placeholder { /* IE 10+ */
  color: white !important;
}

input::-moz-placeholder { /* Firefox 19+ */
  opacity: 1; /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
  color: white !important;
}

input:-moz-placeholder { /* Firefox 18- */
  opacity: 1; /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
  color: white !important;
}

input::placeholder {
  color: white !important;
}

/* Parte interior del box de select, fondo y border */
[class^='select2'] {
  border-radius: 10px !important;
  background-color: black !important;
  color: white !important;
}

/* Eliminar el padding izq interior del select para unificar con los demás campos */
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 14px;
  padding-top: 5px;
}

/* Movemos el arrow del dropdown para no solapar los bordes del select2 */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 5px !important;
  right: 8px !important;
}

/* Parte exterior del box de select, fondo y border */
span.select2-selection.select2-selection--single {
  outline: none;
  background-color: black;
  /*border: solid #33205b 1px !important;*/
  border: solid #c2a5f6 2px !important;
}

/* Color del hover sobre las opciones seleccionables */
.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[aria-selected]:hover {
  background-color: #33205b !important;
}

/* Color de la opción en focus, que no está seleccionada y sin hover */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #c2a5f6 !important;
}

/* Seleccionado pero con focus en otra opción */
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #e1d4fb !important;
}

/* Borde exterior del dropdown */
.select2-container--default .select2-dropdown.select2-dropdown--below {
  /*border: solid #33205b 1px;*/
  border: solid #c2a5f6 1px !important;
}

/* Borde del buscador dentro del select */
.select2-container--default .select2-search--dropdown .select2-search__field, .select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border: 1px solid #41ffb4 !important;
}


.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

.w-fit-content {
  width: fit-content;
}

.flex_column {
  flex-direction: column;
}

body {
  background-color: black;
  height: 100vh;
}

#inicio {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Header */
header {
  margin: 1rem 3rem 0;
}

.logo_mobile {
  display: none;
}

nav a {
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

.dropdown-toggle {
  display: none;

  &::after {
    display: none !important;
  }
}

.navbar-nav {
  flex-direction: row;
  gap: 32px;
}

/* Cookies box */
.cookies_box {
  position: fixed;
  bottom: 100px;
  background-color: #c0a3f4;
  display: flex;
  justify-content: space-between;
  padding: 2rem 3rem 1rem 2rem;
  border-radius: 10px;

  &__text {
    width: 70%;

    p:first-child {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: normal;
    }

    p:nth-child(2) {
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 21%;
    position: relative;
    top: 15px;

    button {
      width: 100%;

    }
  }
}

/* Footer */
.footer {
  &__color {
    color: #C0A3F4;

    a {
      color: #C0A3F4;
    }
  }

  &__rrss {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    float: right;
    width: 127px;
    margin-right: 3rem;
  }

  &__line {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 3rem 0;
  }

  &__copyright {
    width: fit-content;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: fit-content;
  }

  &__long_cookies {
    color: inherit;
  }

  &__short_cookies {
    display: none;
    color: inherit;
  }
}

.after_footer_button {
  display: none;
}

@media (max-width: 1050px) {
  .cookies_box {
    flex-direction: column;

    &__text {
      width: 100%;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 18px;
      width: 100%;
      position: static;

      button {
        width: 250px;
        height: 40px;
      }
    }
  }

}

@media (max-width: 960px) {
  header {
    margin: 1rem 2rem 0;
  }
  .logo_desktop {
    width: 45px;
  }
  .navbar-nav {
    gap: 20px;
  }
  .button {
    width: 140px;
    height: 35px;
  }
  .footer__rrss img {
    width: 25px;
  }
}

@media (max-width: 900px) {
  .logo_desktop {
    width: 36px;
  }
  .button {
    width: 120px;
    height: 30px;
  }

}

@media (max-width: 768px) {
  header {
    margin: 0 1rem 0;
  }
  nav {
    padding-top: 0 !important;
  }
  .nav_desktop {
    display: none;
  }
  .logo_desktop {
    display: none;
  }
  .logo_mobile {
    display: block;
  }
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    bottom: 8px;
    cursor: pointer;

    i {
      color: #41FFB2;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .dropdown_toggle_bar_1 {
    width: 30px;
    height: 2px;
    margin: 0 0 2px;
    background-color: #41ffb2;
  }
  .dropdown_toggle_bar_2 {
    width: 30px;
    height: 2px;
    margin: 2px 0;
    background-color: #41ffb2;
  }
  .dropdown_toggle_bar_3 {
    width: 30px;
    height: 2px;
    margin: 2px 0 0;
    background-color: #41ffb2;
  }
  .show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
  }

  .dropdown-menu {
    position: absolute;
    left: unset;
    right: 0;
    border-radius: 12px;
    width: 220px;
    font-size: 12px;
    font-weight: 300;

    a {
      height: 35px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 4px 16px;

      &:hover {
        border-radius: 8px;
        background-color: #41ffb2;
      }
    }
  }

  .cookies_box {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60%;
    flex-direction: column;
    width: 75%;
    padding: 2rem 3rem;

    &__text {
      p {
        text-align: center !important;
      }
    }

    &__actions {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

    &__line {
      margin: 0 1rem 0;
    }

    &__rrss {
      width: 100px;
      margin-right: 1rem;
    }
  }

  .after_footer_button {
    display: block;
    width: 100%;
    border-radius: 0;
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 435px) {
  .cookies_box {
    width: 75%;
    height: 75%;
  }

  .footer {

    &__rrss {
      width: 100%;
      justify-content: center;
      gap: 24px;
      margin-right: 0;
      margin-bottom: 40px;

      img {
        width: 25px;
      }
    }

    &__copyright {
      width: 100%;
    }

    &__links {
      width: 100%;
    }

    &__short_cookies {
      display: block;
    }

    &__long_cookies {
      display: none;
    }
  }
}

@media (max-width: 380px) {
  .cookies_box {
    width: 85%;
    height: 80%;
  }
}

@media (max-width: 325px) {
  .cookies_box {
    width: 95%;
    height: 90%;
  }
}
