.color_1 {
  color: #41ffb4;
}

.color_2 {
  color: #c2a5f6;
}

.color_3 {
  color: #33205b;
}

.color_4 {
  color: #e1d4fb;
}

.color_5 {
  color: #87CED8;
}

.color_6 {
  color: #000000;
}

.background_color_1 {
  background-color: #41ffb4;
}

.background_color_2 {
  background-color: #c2a5f6;
}

.background_color_3 {
  background-color: #33205b;
}

.background_color_4 {
  background-color: #e1d4fb;
}

.background_color_5 {
  background-color: #87CED8;
}

.background_color_6 {
  background-color: #000000;
}



